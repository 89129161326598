<template>
  <div class="models__cars">
    <div
      v-for="model in models"
      :key="model.id"
      :id="model.id"
      class="models__car"
    >
      <div class="models__car-header">
        <div class="models__car-name">
          <h3>{{ model.name }}</h3>
          <div v-if="model.gift" class="models__car-gift">
            <img src="@/img/icons/gift-g.svg" alt="icon gift" />
            <p class="models__car-gift__text">Подарок от дилера</p>
          </div>
        </div>
      </div>
      <div class="models__car-grid">
        <img
          :src="require(`../../img/pics/cars/${model.id}/${model.id}.png`)"
          :alt="model.name"
          class="models__car-image"
        />
        <div class="models__car-desc">
          <h4 class="models__car__price">Цена от {{ model.price }} ₽</h4>
          <h4 class="models__car__price">Выгода до <span>700 000 ₽</span></h4>

          <div class="models__car-details">
            <p>
              Выгода по трейд-ин до <span>{{ getProfit(model.id) }} руб</span>
            </p>
            <p v-if="model.installments" class="models__car__installments">
              Авторассрочка
            </p>
            <p v-if="model.credit" class="models__car__credit">
              Кредит от <span>{{ model.credit }} ₽</span>
            </p>
            <p v-if="model.rate" class="models__car__credit-rate">
              Ставка от <span>{{ model.rate }}%</span>
            </p>

            <p
              v-if="model.insurance_years || model.insurance_kilometrage"
              class="models__car__rate"
            >
              <span>{{ model.insurance_years }} </span> лет гарантии и поддержки
            </p>
            <p v-if="model.wheels">Зимняя резина в подарок</p>
          </div>
        </div>
        <div class="models__car-buttons">
          <button
            @click="toggleRequestForm($event, model.name)"
            class="models__car-btn models__car-btn-2 btn-primary"
          >
            Рассчитать кредит
          </button>
          <button
            @click="toggleRequestForm($event, model.name)"
            class="models__car-btn models__car-btn-1 btn-secondary"
          >
            Узнать цену
          </button>
          <button
            class="models__car-btn models__car-btn-3 btn-secondary"
            @click="toggleRequestForm($event, model.name)"
          >
            Подобрать комплектацию
          </button>
        </div>

        <ModelSlider :model="model" />
      </div>
    </div>
  </div>
</template>

<script>
import siteData from "@/config.json";
import ModelSlider from "./ModelSlider.vue";

export default {
  name: "ModelComponent",
  data() {
    return {
      models: siteData.models_range.models,
    };
  },
  components: { ModelSlider },

  methods: {
    toggleRequestForm(event, model) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
        car: model,
        form_position: "форма модельного ряда",
      });
    },
    getProfit(id) {
      return dictionary[id];
    },
  },
};

const dictionary = {
  x50: "200 000",
  x70: "350 000",
};
</script>
